import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import styled from "styled-components";
import {
  mainRed,
  mainWhite,
  mainBlack,
  innerWidth,
  mainYellow,
  lightGrey,
  screen,
} from "../components/common/variables";
import previewDataImg from "../images/data-preview.jpg";
import SEO from "../components/seo";
import StyledButton from "../components/styled-button";
import SubBanner from "../components/banner/sub";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Wrapper = styled.div`
  .banner {
    position: relative;
    background: ${lightGrey};
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
      border-bottom: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      .featured-txt {
        text-align: center;
        @media ${screen.small} {
          text-align: left;
        }

        .inner-txt {
          .capital-heading {
            margin: 12px 0 0 0;
            @media ${screen.xsmall} {
              margin: 25px 0 0 0;
            }
          }
        }
      }
    }
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 41px 0 50px 0;
    display: flex;
    flex-direction: column;
    @media ${screen.xsmall} {
      padding: 100px 42px;
    }
    @media ${screen.small} {
      flex-direction: row;
      padding: 100px 30px;
    }
    @media ${screen.large} {
      padding: 110px 0;
    }

    strong {
      font-weight: 600;
    }
  }

  .row {
    background: ${mainWhite};

    .col {
      @media ${screen.small} {
        width: 50%;
      }

      .capital-subheading {
        margin: 0 0 22px 0;
        text-align: center;
        @media ${screen.xsmall} {
          margin: 0 0 30px 0;
        }
        @media ${screen.small} {
          max-width: 550px;
          text-align: left;
        }
        @media ${screen.large} {
          margin: 0 0 35px 0;
        }
      }

      &--right {
        @media ${screen.small} {
          margin: 0 0 0 10px;
        }

        .bordered-p {
          border-bottom: 2px ${mainRed} solid;
          margin: 0 0 25px 0;
          padding: 0 0 25px 0;
          text-align: center;
          @media ${screen.xsmall} {
            padding: 0 0 50px 0;
            margin: 0 0 50px 0;
            text-align: left;
          }

          p {
            font-size: 1rem;
            font-weight: 300;
            margin: 0 0 20px 0;
            @media ${screen.large} {
              font-size: 1.1rem;
            }

            &:last-child {
              margin: 0;
            }
          }

          &:last-child {
            border-bottom: none;
            padding: 0;
            margin: 0;
          }

          &--bold {
            p {
              font-size: 1.22rem;
              font-weight: 400;
              @media ${screen.xsmall} {
                font-size: 1.4rem;
              }
              @media ${screen.large} {
                font-size: 1.5rem;
              }
            }
          }
        }

        .inner-flex-row {
          margin: 25px auto 40px auto;
          @media ${screen.xsmall} {
            margin: 25px auto 50px auto;
            display: flex;
          }
          @media ${screen.small} {
            margin: 35px 0 0 0;
          }

          img {
            display: block;
            max-width: 132px;
            width: 100%;
            margin: 0 auto 20px auto;
            pointer-events: none;
            @media ${screen.xsmall} {
              margin: 0 30px 0 0;
            }
          }

          .dl-link {
            display: table;
            margin: 0 auto;
            @media ${screen.xsmall} {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .donate-details {
    position: relative;
    @media ${screen.xsmall} {
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-bottom: 17px ${mainWhite} solid;
    }

    .col {
      @media ${screen.small} {
        width: 50%;
      }

      .capital-subheading {
        margin: 0 0 22px 0;
        text-align: center;

        @media ${screen.xsmall} {
          margin: 0 0 30px 0;
        }
        @media ${screen.small} {
          text-align: left;
          max-width: 550px;
        }
        @media ${screen.large} {
          margin: 0 0 35px 0;
        }
      }

      &--right {
        @media ${screen.small} {
          margin: 0 0 0 10px;
        }

        .bordered-p {
          border-bottom: 2px ${mainRed} solid;
          margin: 0 0 25px 0;
          padding: 0 0 30px 0;
          text-align: center;
          @media ${screen.xsmall} {
            padding: 0 0 45px 0;
            margin: 0 0 45px 0;
            text-align: left;
          }

          p {
            font-size: 1rem;
            font-weight: 300;

            @media ${screen.large} {
              font-size: 1.1rem;
            }

            .heading {
              display: block;
              font-size: 1.22rem;
              font-weight: 400;
              margin: 0 0 6px 0;
              @media ${screen.xsmall} {
                font-size: 1.4rem;
                margin: 0 0 12px 0;
              }
              @media ${screen.large} {
                font-size: 1.5rem;
              }
            }

            .break {
              display: block;
            }
          }

          &--top {
            display: flex;
            flex-direction: column;

            @media ${screen.xsmall} {
              flex-direction: row;
              align-items: flex-end;
              justify-content: space-between;
            }

            .btn {
              margin: 20px auto 0 auto;
              background: none;
              border: 2px ${mainRed} solid;
              color: ${mainBlack};
              display: inline-block;
              font-size: 1.02rem;
              font-weight: 600;
              padding: 13px 23px 12px 23px;
              border-radius: 5px;
              letter-spacing: 3px;
              outline: none;
              cursor: pointer;
              line-height: 20px;
              min-width: 180px;
              text-align: center;
              @media ${screen.xsmall} {
                min-width: 170px;
                max-width: none;
                margin: 0 0 0 20px;
              }
              @media ${screen.large} {
                padding: 15px 26px 14px 26px;
                font-size: 1.1rem;
              }
              @media ${screen.withCursor} {
                &:hover {
                  background: ${mainYellow};
                  border: 2px ${mainYellow} solid;
                  color: ${mainWhite};
                }
              }
            }
          }
        }
      }
    }
  }

  .member {
    border-bottom: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-bottom: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      padding: 28px 0 50px 0;
      @media ${screen.xsmall} {
        padding: 50px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .featured-txt {
        .inner-txt {
          color: ${mainWhite};
          text-align: center;
          padding: 0 22px;
          @media ${screen.small} {
            text-align: left;
            padding: 0;
          }
          .capital-subheading {
          }

          .yellow-heading {
            color: ${mainYellow};
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: 3px;
            line-height: 1.4;
            text-transform: uppercase;
            margin: 13px 0 12px 0;
            @media ${screen.xsmall} {
              font-size: 1.25rem;
            }
            @media ${screen.large} {
              font-size: 1.42rem;
            }
          }

          .description {
            p {
              font-size: 1rem;
              font-weight: 300;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.1rem;
              }
            }
          }

          .btn {
            a {
              border: 2px ${mainYellow} solid;
              color: ${mainWhite};
              @media ${screen.withCursor} {
                &:hover {
                  background: ${mainYellow};
                }
              }
            }
          }
        }
      }
    }

    .absolute-bg {
      order: -1;
      background-position: top;
    }
  }

  .donation-form {
    text-align: center;
    background: ${mainBlack};
    min-height: 50vh;

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 22px 50px 22px;
      display: block;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .capital-subheading {
        color: ${mainWhite};
        text-align: center;
        margin: 0 0 50px 0;
      }

      iframe {
        border: 0;
        width: 100%;
        height: 765px;
      }
    }
  }
`;

const DonatePage = ({ data }) => {
  const {
    title_tag,
    meta_description,
    banner_heading,
    banner_image,
    donation_count_heading,
    donation_count_top_description,
    donation_count_bottom_description,
    choir_member_full_name,
    choir_member_position_title,
    choir_member_image,
    choir_member_description,
  } = data.content.data;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <SubBanner className="banner" bgUrl={banner_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <p className="red-heading">GET INVOLVED</p>
                <h1 className="capital-heading">{banner_heading.text}</h1>
              </div>
            </div>
          </div>
        </SubBanner>

        <section className="row">
          <div className="inner-wrapper">
            <div className="col">
              <h3 className="capital-subheading">
                {donation_count_heading.text}
              </h3>
            </div>

            <div className="col col--right">
              <div>
                <div
                  className="bordered-p bordered-p--bold"
                  dangerouslySetInnerHTML={{
                    __html: donation_count_top_description.html,
                  }}
                />
                <div
                  className="bordered-p"
                  dangerouslySetInnerHTML={{
                    __html: donation_count_bottom_description.html,
                  }}
                />
              </div>
              <div className="inner-flex-row">
                <img src={previewDataImg} alt="Preview" />
                <StyledButton
                  className="dl-link"
                  href="https://sydneystreetchoir.org.au/pdf/Sydney-Street-Choir-Impact-Data.pdf"
                >
                  DOWNLOAD NOW
                </StyledButton>
              </div>
            </div>
          </div>
        </section>

        <SubBanner className="member" bgUrl={choir_member_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <h3 className="capital-subheading">
                  MEET {choir_member_full_name.text}
                </h3>
                <p className="yellow-heading">{choir_member_position_title}</p>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: choir_member_description.html,
                  }}
                />
                <StyledButton className="btn" to="/meet-the-choir/">
                  MEET THE MEMBERS
                </StyledButton>
              </div>
            </div>
          </div>
        </SubBanner>

        <section className="donate-details">
          <div className="inner-wrapper">
            <div className="col">
              <h3 className="capital-subheading">Donate today</h3>
            </div>

            <div className="col col--right">
              <div>
                <div className="bordered-p bordered-p--top">
                  <p>
                    <span className="heading">Online</span>
                    You can now donate to Sydney Street Choir online and
                    securely.
                  </p>
                  <AnchorLink
                    className="btn"
                    href="#donation-form"
                    offset={() => 100}
                  >
                    DONATE
                  </AnchorLink>
                </div>
                <div className="bordered-p">
                  <p>
                    <span className="heading">EFT</span>
                    Make a funds transfer to the following account:
                    <strong className="break">
                      Sydney Street Choir Foundation
                    </strong>
                    <strong className="break">BSB 062-498</strong>
                    <strong className="break">Account Number 10136843</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="donation-form" id="donation-form">
          <div className="inner-wrapper">
            <h5 className="capital-subheading">
              Donate to the sydney street choir
            </h5>
            <iframe
              src="https://sydney-street-choir-donate.raisely.com/embed/"
              title="donate-form"
            ></iframe>
          </div>
        </section>
      </Wrapper>
    </Layout>
  );
};

export default DonatePage;

export const dataQuery = graphql`
  {
    content: prismicDonatePage {
      data {
        title_tag
        meta_description
        banner_heading {
          text
        }
        banner_image {
          alt
          url
        }
        donation_count_heading {
          text
        }
        donation_count_top_description {
          html
        }
        donation_count_bottom_description {
          html
        }
        choir_member_full_name {
          text
        }
        choir_member_position_title
        choir_member_image {
          alt
          url
        }
        choir_member_description {
          html
        }
      }
    }
  }
`;
